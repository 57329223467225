import { render, staticRenderFns } from "./duyuruKategori.vue?vue&type=template&id=bdebb748&scoped=true&"
import script from "./duyuruKategori.vue?vue&type=script&lang=js&"
export * from "./duyuruKategori.vue?vue&type=script&lang=js&"
import style0 from "./duyuruKategori.vue?vue&type=style&index=0&id=bdebb748&scoped=true&lang=css&"
import style1 from "./duyuruKategori.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bdebb748",
  null
  
)

export default component.exports