<template>
  <div>
    <hr v-if="!spinner">
    <b-spinner
        v-if="spinner"
        variant="primary"
        class="spinners"
    />
    <div v-if="!spinner && !duyuruShow">
      <b-row class="mt-1">
        <b-col lg="8" md="6" sm="12" cols="12">
          <b-card-actions
              class="cari-liste-card duyuruKategoriCard"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">Duyuru Kategorileri</h4>
                </b-col>
              </div>
            </b-row>
            <hr class="mt-0">
            <b-table
                class="table-striped mt-2"
                empty-text="Veri Bulunamadı"
                striped
                hover
                :items="salaryListItems.slice().reverse()"
                :fields="fields"
            >
              <template #cell(actions)="row">
                <b-row class="myRow">
                  <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="bgSuccess"
                      class="btn-icon rounded-circle mr-1"
                      size="sm"
                      @click="kategoriDetay(row.item)"
                  >
                    <!-- v-b-modal.kategoriDetay -->
                    <feather-icon icon="AirplayIcon"/>
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-modal.kategoriEdit
                      variant="bgInfo"
                      class="btn-icon rounded-circle mr-1"
                      size="sm"
                      @click="kategori_U_run(row.item)"
                  >
                    <feather-icon icon="EditIcon"/>
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="bgDanger"
                      class="btn-icon rounded-circle mr-1"
                      size="sm"
                      @click="kategoriRemove(row.item)"
                  >
                    <feather-icon icon="TrashIcon"/>
                  </b-button>
                </b-row>
              </template>
            </b-table>
          </b-card-actions>
        </b-col>
        <b-col lg="4" md="6" sm="12" cols="12">
          <b-card-actions
              class="cari-liste-card"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">Kategori Kaydı</h4>
                </b-col>
              </div>
            </b-row>
            <hr class="mt-0">
            <b-form-group
                label="Kategori Adı"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input
                  id="basicInput"
                  v-model="kategoriName"
                  autocomplete="off"
                  @keyup.enter="kategoriSave"
              />
            </b-form-group>
            <b-row>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgInfo"
                  class="mt-1 mr-1 border-0"
                  :style="{backgroundColor:$store.getters.bgSuccess}"
                  @click="kategoriSave"
              >
                Kaydet
              </b-button>
            </b-row>
          </b-card-actions>
        </b-col>
      </b-row>
    </div>
    <b-row v-if="duyuruShow">
      <b-col lg="7" md="6" sm="12" cols="12">
        <b-card-actions
            class="cari-liste-card"
        >
          <b-row class="mt-50 mb-3">
            <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
              <b-col cols="12">
                <h4 class="text-white d-inline-block">{{ chooseKategoriName }} Kategorisine Ait Duyuru
                  Listesi</h4>
              </b-col>
            </div>
          </b-row>
          <hr>
          <table class="table table-striped">
            <thead>
            <tr>
              <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                KATEGORİ
              </th>
              <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                BAŞLIK
              </th>
              <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                DURUM
              </th>
              <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                KAYIT TARİHİ
              </th>
              <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"/>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(item, index) in kategoriDetayList"
                :key="index"
            >
              <td>
                {{ item.kategoriBaslik }}
              </td>
              <td>{{ item.duyuruBaslik }}</td>
              <td>
                <b-form-checkbox
                    class="custom-control-primary"
                    switch
                    :checked="item.durum == 1 ? true : false"
                    @change="duyuruDurumUpdate(item.duyuruID)"
                >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon"/>
                    </span>
                  <span class="switch-icon-right">
                      <feather-icon icon="XIcon"/>
                    </span>
                </b-form-checkbox>
              </td>
              <td>{{ item.kayitTarih }}</td>
              <td>
                <b-button
                    v-b-modal.duyuruResim
                    variant="bgSuccess"
                    class="btn-icon rounded-circle mr-1"
                    @click="duyuruResim(item)"
                >
                  <feather-icon icon="ImageIcon"/>
                </b-button>
                <b-button
                    v-b-modal.duyuruDosya
                    variant="bgWarning"
                    class="btn-icon rounded-circle mr-1 text-white"
                    @click="duyuruDosya(item)"
                >
                  <feather-icon icon="FileIcon"/>
                </b-button>
                <b-button
                    v-b-modal.duyuruEdit
                    variant="bgInfo"
                    class="btn-icon rounded-circle mr-1"
                    @click="duyuruDuzenle(item)"
                >
                  <feather-icon icon="EditIcon"/>
                </b-button>
                <b-button
                    variant="bgDanger"
                    class="btn-icon rounded-circle"
                    @click="kategoriDetayRemove(item.duyuruID)"
                >
                  <feather-icon icon="TrashIcon"/>
                </b-button>
              </td>
            </tr>
            </tbody>
          </table>
        </b-card-actions>
      </b-col>
      <b-col lg="5" md="6" sm="12" cols="12">
        <b-card-actions
            class="cari-liste-card"
        >
          <b-row class="mt-50 mb-3">
            <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
              <b-col cols="12">
                <h4 class="text-white d-inline-block">Duyuru Kaydı</h4>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="bgInfo"
                    @click="duyuruShow = false"
                    class="border-0 float-right"
                    :style="{backgroundColor:$store.getters.cardTitleButton}"
                >
                  Kategorilere Dön
                </b-button>
              </b-col>
            </div>
          </b-row>
          <hr class="mt-0">
          <b-form-group
              label="Duyuru Başlığı"
              label-for="basicInput"
              class="mt-1"
          >
            <b-form-input
                id="basicInput"
                v-model="newBaslik"
                placeholder="Başlık"
                autocomplete="off"
            />
          </b-form-group>
          <b-form-group
              label="İçerik"
              label-for="basicInput"
          >
            <quill-editor
                v-model="content"
                role="4"
                :options="snowOption"
            />
          </b-form-group>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mr-1 border-0"
              variant="bgInfo"
              :style="{backgroundColor:$store.getters.bgSuccess}"
              @click="duyuruKaydet"
          >
            Kaydet
          </b-button>
        </b-card-actions>
      </b-col>
    </b-row>
    <b-modal
        id="kategoriEdit"
        cancel-variant="secondary"
        ok-only
        ok-title="Güncelle"
        ok-variant="bgInfo"
        centered
        size="sm"
        title="Kategoriyi Düzenle"
        @ok="kategoriUpdate"
    >
      <b-form-group
          label="Kategori Adı"
          label-for="basicInput"
      >
        <b-form-input
            id="basicInput"
            v-model="kategori_U_name"
            autocomplete="off"
            @keyup.enter="kategoriUpdate"
        />
      </b-form-group>
    </b-modal>
    <b-modal
        id="kategoriDetay"
        ok-only
        ok-title="Gizle"
        ok-variant="bgInfo"
        centered
        size="xl"
        title="Kategori Detay"
        @ok="this.$bvModal.hide('kategoriDetay')"
        @hide="clearDetails"
    >
      <b-row>
        <b-col cols="7">
          <b-card>
            <b-row>
              <b-col cols="12">
                <h4>
                  {{ chooseKategoriName }} Kategorisine Ait Duyuru Listesi
                </h4>
              </b-col>
            </b-row>
            <hr class="mt-0">
            <table class="table table-striped">
              <thead>
              <tr>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  KATEGORİ
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  BAŞLIK
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  DURUM
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  KAYIT TARİHİ
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"/>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(item, index) in kategoriDetayList"
                  :key="index"
              >
                <td>
                  {{ item.kategoriBaslik }}
                </td>
                <td>{{ item.duyuruBaslik }}</td>
                <td>
                  <b-form-checkbox
                      class="custom-control-primary"
                      switch
                      :checked="item.durum == 1 ? true : false"
                      @change="duyuruDurumUpdate(item.duyuruID)"
                  >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon"/>
                      </span>
                    <span class="switch-icon-right">
                        <feather-icon icon="XIcon"/>
                      </span>
                  </b-form-checkbox>
                </td>
                <td>{{ item.kayitTarih }}</td>
                <td>
                  <b-button
                      v-b-modal.duyuruResim
                      variant="bgSuccess"
                      class="btn-icon rounded-circle mr-1"
                      @click="duyuruResim(item)"
                  >
                    <feather-icon icon="ImageIcon"/>
                  </b-button>
                  <b-button
                      v-b-modal.duyuruDosya
                      variant="bgWarning"
                      class="btn-icon rounded-circle mr-1 text-white"
                      @click="duyuruDosya(item)"
                  >
                    <feather-icon icon="FileIcon"/>
                  </b-button>
                  <b-button
                      v-b-modal.duyuruEdit
                      variant="bgInfo"
                      class="btn-icon rounded-circle mr-1"
                      @click="duyuruDuzenle(item)"
                  >
                    <feather-icon icon="EditIcon"/>
                  </b-button>
                  <b-button
                      variant="bgDanger"
                      class="btn-icon rounded-circle"
                      @click="kategoriDetayRemove(item.duyuruID)"
                  >
                    <feather-icon icon="TrashIcon"/>
                  </b-button>
                </td>
              </tr>
              </tbody>
            </table>
          </b-card>
        </b-col>
        <b-col cols="5">
          <b-card>
            <b-row>
              <b-col cols="12">
                <h4>Duyuru Kaydı</h4>
              </b-col>
            </b-row>
            <hr class="mt-0">
            <b-form-group
                label="Duyuru Başlığı"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input
                  id="basicInput"
                  v-model="newBaslik"
                  placeholder="Başlık"
                  autocomplete="off"
              />
            </b-form-group>
            <b-form-group
                label="İçerik"
                label-for="basicInput"
            >
              <quill-editor
                  v-model="content"
                  role="4"
                  :options="snowOption"
              />
            </b-form-group>
          </b-card>
          <b-row>
            <b-col
                cols="12"
                class="text-right"
            >
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="mr-1 border-0"
                  variant="bgInfo"
                  :style="{backgroundColor:$store.getters.bgSuccess}"
                  @click="duyuruKaydet"
              >
                Kaydet
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
        id="duyuruEdit"
        ok-only
        centered
        size="xl"
        hide-footer
        @hide="editHide"
    >
      <b-row>
        <b-col cols="12">
          <b-card-actions
              class="cari-liste-card"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">Duyuru Düzenle</h4>
                </b-col>
              </div>
            </b-row>
            <b-form-group
                label="Duyuru Başlığı"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input
                  id="basicInput"
                  v-model="currentDuyuruTitle"
                  placeholder="Başlık"
                  autocomplete="off"
              />
            </b-form-group>
            <b-form-group
                label="İçerik"
                label-for="basicInput"
            >
              <quill-editor
                  v-model="currentDuyuruContent"
                  :options="snowOption"
              />
            </b-form-group>
          </b-card-actions>
          <b-row>
            <b-col cols="12">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="mr-1 border-0"
                  variant="bgInfo"
                  :style="{backgroundColor:$store.getters.bgSuccess}"
                  @click="editSave"
              >
                Kaydet
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
        id="duyuruResim"
        ok-only
        centered
        size="xl"
        hide-footer
        @hide="resimHide"
    >
      <b-row>
        <b-col cols="8">
          <b-card-actions
              class="cari-liste-card"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">Resimler</h4>
                </b-col>
              </div>
            </b-row>
            <hr class="mt-0">
            <table class="table table-striped">
              <thead>
              <tr>
                <th scope="col">
                  FOTO ÖNİZLEME
                </th>
                <th scope="col">
                  FOTO AÇIKLAMASI
                </th>
                <th scope="col"/>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(resim, index) in resimler"
                  :key="index"
              >
                <td>
                  <b-img
                      :src="imagePath(resim.foto)"
                      fluid
                      alt="Responsive image"
                  />
                </td>
                <td>{{ resim.aciklama }}</td>
                <td>
                  <b-button
                      variant="bgDanger"
                      class="btn-icon rounded-circle"
                      @click="resimRemove(resim.fotoID)"
                  >
                    <feather-icon icon="TrashIcon"/>
                  </b-button>
                </td>
              </tr>
              </tbody>
            </table>
          </b-card-actions>
        </b-col>
        <b-col cols="4">
          <b-card-actions
              class="cari-liste-card"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">Resim Ekle</h4>
                </b-col>
              </div>
            </b-row>
            <hr class="mt-0">
            <b-form-group
                label="Dosya Açıklaması"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input
                  id="basicInput"
                  v-model="resimAciklama"
                  placeholder="dosya açıklamasi"
                  autocomplete="off"
              />
            </b-form-group>
            <b-form-group
                label="resim"
                label-for="basicInput"
            >
              <b-form-file
                  type="file"
                  placeholder="Dosya Seçiniz"
                  drop-placeholder="Drop file here..."
                  accept=".jpg, .png, .gif, .jpeg"
                  :file-name-formatter="formatNames"
                  multiple
                  @change="onFileSelected"
              />
            </b-form-group>
          </b-card-actions>
          <b-row>
            <b-col cols="8">
              <b-progress
                  v-model="value"
                  variant="bgSuccess"
                  max="100"
              />
            </b-col>
            <b-col cols="4">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="mr-1 border-0"
                  variant="bgInfo"
                  :style="{backgroundColor:$store.getters.bgSuccess}"
                  @click="onUpload"
              >
                Resmi Yükle
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
        id="duyuruDosya"
        ok-only
        centered
        size="xl"
        hide-footer
        @hide="dosyaHide"
    >
      <b-row>
        <b-col cols="8">
          <b-card-actions
              class="cari-liste-card"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">Dosyalar</h4>
                </b-col>
              </div>
            </b-row>
            <table class="table table-striped">
              <thead>
              <tr>
                <th scope="col">
                  DOSYA ADI
                </th>
                <th scope="col">
                  DOSYA AÇIKLAMASI
                </th>
                <th scope="col"/>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(dosya, index) in dosyalar"
                  :key="index"
              >
                <td>
                  {{ dosya.dosya }}
                </td>
                <td>{{ dosya.aciklama }}</td>
                <td>
                  <b-button
                      variant="bgDanger"
                      class="btn-icon rounded-circle"
                      @click="dosyaRemove(dosya.dosyaID)"
                  >
                    <feather-icon icon="TrashIcon"/>
                  </b-button>
                </td>
              </tr>
              </tbody>
            </table>
          </b-card-actions>
        </b-col>
        <b-col cols="4">
          <b-card-actions
              class="cari-liste-card"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">Dosya Ekle</h4>
                </b-col>
              </div>
            </b-row>
            <b-form-group
                label="Dosya Açıklaması"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input
                  id="basicInput"
                  v-model="dosyaAciklama"
                  placeholder="dosya açıklamasi"
                  autocomplete="off"
              />
            </b-form-group>
            <b-form-group
                label="Lütfen Dosya Seçiniz"
                label-for="basicInput"
            >
              <b-form-file
                  type="file"
                  placeholder="Dosya Seçiniz"
                  drop-placeholder="Drop file here..."
                  :file-name-formatter="formatNames"
                  @change="onFileSelectedDosya"
              />
            </b-form-group>
          </b-card-actions>
          <b-row>
            <b-col cols="8">
              <b-progress
                  v-model="fileValue"
                  max="100"
              />
            </b-col>
            <b-col cols="4">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="mr-1 border-0"
                  variant="bgInfo"
                  @click="onUploadFile"
                  :style="{backgroundColor:$store.getters.bgSuccess}"
              >
                Dosya Yükle
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BProgress,
  BRow,
  BCol,
  BCard,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormCheckbox,
  BFormCheckboxGroup,
  BTable,
  BModal,
  BImg,
  BInputGroup,
  BContainer,
  BSpinner,
  BFormInput,
  BPagination,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormFile,
} from 'bootstrap-vue'

export default {
  components: {
    BCardActions,
    BProgress,
    BButton,
    BContainer,
    BAvatar,
    BCard,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BImg,
    BInputGroupAppend,
    BSpinner,
    BInputGroupPrepend,
    BFormCheckbox,
    BFormCheckboxGroup,
    BTable,
    BModal,
    BInputGroup,
    quillEditor,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      duyuruShow: false,
      spinner: true,
      chooseKategoriName: '',
      currentDuyuruID: '',
      fileValue: 0,
      value: 0,
      aciklama: '',
      detailsAciklama: '',
      selectedFile: [],
      selectedFileDosya: [],
      kategoriName: '',
      kategori_U_name: '',
      kategori_U_id: '',
      content: '',
      newBaslik: '',
      resimAciklama: '',
      dosyaAciklama: '',
      fields: [
        {
          key: 'baslik',
          label: 'BAŞLIK',
          sortable: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
      ],
      snowOption: {
        placeholder: 'İçerik Giriniz',
      },
    }
  },
  computed: {
    currentDuyuruContent: {
      get() {
        return this.$store.state.webPanel.duyuruEditContent.icerik
      },
      set(val) {
        this.$store.state.webPanel.duyuruEditContent.icerik = val
      },
    },
    currentDuyuruTitle: {
      get() {
        return this.$store.state.webPanel.duyuruEditContent.baslik
      },
      set(val) {
        this.$store.state.webPanel.duyuruEditContent.baslik = val
      },
    },
    dosyalar() {
      return this.$store.state.webPanel.duyuruDosya.liste
    },
    resimler() {
      return this.$store.state.webPanel.duyuruResim.liste
    },
    kategoriDetayList() {
      return this.$store.state.webPanel.kategoriDetay.liste
    },
    salaryListItems() {
      return this.$store.state.webPanel.duyuru
    },
  },
  created() {
    this.$store
        .dispatch('duyuru')
        .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
        })
        .catch(err => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  beforeDestroy() {
    this.$store.commit('clearDuyuru')
  },
  methods: {
    imagePath(path) {
      return (
          `${this.$store.state.API_URL}/userFiles/${
              localStorage.getItem('otelFirmaKodu')
          }/Galeri/kucuk/${
              path}`
      )
    },
    onUploadFile() {
      if (
          this.selectedFileDosya !== 'undefined'
          && this.selectedFileDosya.length !== 0
      ) {
        const fd = new FormData()
        fd.append('dosya', this.selectedFileDosya[0])
        fd.append('crm_token', localStorage.getItem('otelUserToken'))
        fd.append('serviceName', 'web')
        fd.append('methodName', 'duyuruDosya')
        fd.append('aciklama', this.dosyaAciklama)
        fd.append(
            'duyuruID',
            this.$store.state.webPanel.duyuruDosya.duyuru.duyuruID,
        )
        fd.append('dosyaKaydi', true)
        axios
            .post(store.state.POST_URL, fd, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              onUploadProgress: function (progressEvent) {
                this.fileValue = parseInt(
                    Math.round((progressEvent.loaded / progressEvent.total) * 95),
                )
              }.bind(this),
            })
            .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
              this.$store.dispatch('duyuruDosya', {
                duyuruID: this.currentDuyuruID,
              })
              this.selectedFileDosya = []
              this.dosyaAciklama = ''
              this.fileValue = 0
              const mesaj = this.$store.getters.notificationSettings(res.data)
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            })
            .catch(err => {
              this.fileValue = 0
              const mesaj = this.$store.getters.notificationSettings(err)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'warning',
                },
              })
            })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Dosya Seçiniz',
            icon: 'ThumbsUpIcon',
            variant: 'warning',
          },
        })
      }
    },
    onUpload() {
      if (this.selectedFile !== 'undefined' && this.selectedFile.length !== 0) {
        const fd = new FormData()
        this.selectedFile.forEach((element, index) => {
          fd.append('resim[]', element)
        })
        fd.append('crm_token', localStorage.getItem('otelUserToken'))
        fd.append('serviceName', 'web')
        fd.append('methodName', 'duyuruGaleri')
        fd.append('aciklama', this.resimAciklama)
        fd.append(
            'duyuruID',
            this.$store.state.webPanel.duyuruResim.duyuru.duyuruID,
        )
        fd.append('fotoKaydi', true)
        axios
            .post(store.state.POST_URL, fd, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              onUploadProgress: function (progressEvent) {
                this.value = parseInt(
                    Math.round((progressEvent.loaded / progressEvent.total) * 95),
                )
              }.bind(this),
            })
            .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
              this.$store.dispatch('duyuruResim', {
                duyuruID: this.currentDuyuruID,
              })
              this.selectedFile = []
              this.resimAciklama = ''
              this.value = 0
              const mesaj = this.$store.getters.notificationSettings(res.data)
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            })
            .catch(err => {
              this.value = 0
              const mesaj = this.$store.getters.notificationSettings(err)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'warning',
                },
              })
            })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Resim Seçiniz',
            icon: 'ThumbsUpIcon',
            variant: 'warning',
          },
        })
      }
    },
    duyuruKaydet() {
      if (this.newBaslik) {
        this.$store
            .dispatch('duyuruSave', {
              kategoriID:
              this.$store.state.webPanel.kategoriDetay.kategori.kategoriID,
              baslik: this.newBaslik,
              icerik: this.content,
            })
            .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
              (this.newBaslik = ''), (this.content = '')
              const mesaj = this.$store.getters.notificationSettings(res)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              })
            })
            .catch(err => {
              const mesaj = this.$store.getters.notificationSettings(err)
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Hata!',
                      text: mesaj,
                      icon: 'ThumbsDownIcon',
                      variant: 'danger',
                    },
                  },
                  { position },
              )
            })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Duyuru Başlığı Boş',
            icon: 'ThumbsDownIcon',
            variant: 'danger',
          },
        })
      }
    },
    editSave() {
      this.$store
          .dispatch('duyuruEditSave', {
            edit: this.currentDuyuruID,
            duyuruDuzenle: this.currentDuyuruID,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            this.$bvModal.hide('duyuruEdit')
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    duyuruDosya(item) {
      this.currentDuyuruID = item.duyuruID
      this.$store.dispatch('duyuruDosya', {
        duyuruID: item.duyuruID,
      })
    },
    duyuruResim(item) {
      this.currentDuyuruID = item.duyuruID
      this.$store.dispatch('duyuruResim', {
        duyuruID: item.duyuruID,
      })
    },
    duyuruDuzenle(item) {
      this.currentDuyuruID = item.duyuruID
      this.$store.dispatch('duyuruEdit', {
        edit: item.duyuruID,
      })
    },
    dosyaHide() {
      (this.selectedFileDosya = []), (this.dosyaAciklama = '')
      this.$store.commit('clearDuyuruDosya')
    },
    resimHide() {
      (this.selectedFile = []), (this.resimAciklama = '')
      this.$store.commit('clearDuyuruResim')
    },
    editHide() {
      this.$store.commit('clearDuyuruEditContent')
    },
    duyuruDurumUpdate(val) {
      this.$store
          .dispatch('duyuruDurumUpdate', {
            kategoriID:
            this.$store.state.webPanel.kategoriDetay.kategori.kategoriID,
            duyuruID: val,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            (this.newBaslik = ''), (this.content = '')
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    onFileSelectedDosya(event) {
      this.selectedFileDosya.push(event.target.files[0])
    },
    onFileSelected(event) {
      event.target.files.forEach((image, index) => {
        this.selectedFile.push(image)
      })
    },
    formatNames(files) {
      return files.length === 1
          ? files[0].name
          : `${files.length} dosya seçildi`
    },
    kategoriDetay(gal) {
      this.chooseKategoriName = gal.baslik
      this.$store.dispatch('kategoriDetay', {
        id: gal.kategoriID,
      })
      this.duyuruShow = true
    },
    kategori_U_run(v) {
      this.kategori_U_name = v.baslik
      this.kategori_U_id = v.kategoriID
    },
    kategoriUpdate() {
      this.$store
          .dispatch('kategoriUpdate', {
            baslik: this.kategori_U_name,
            id: this.kategori_U_id,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            this.$bvModal.hide('kategoriEdit')
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    kategoriDetayRemove(val) {
      this.$store
          .dispatch('kategoriDetayRemove', {
            kategoriID:
            this.$store.state.webPanel.kategoriDetay.kategori.kategoriID,
            id: val,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    resimRemove(val) {
      this.$store
          .dispatch('resimRemove', {
            duyuruID: this.currentDuyuruID,
            Sil: val
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    dosyaRemove(val) {
      this.$store
          .dispatch('duyuruDosyaRemove', {
            duyuruID: this.currentDuyuruID,
            Sil: val,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    kategoriRemove(val) {
      this.$store
          .dispatch('kategoriRemove', { id: val.kategoriID })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    clearDetails() {
      (this.content = ''),
          (this.newBaslik = ''),
          this.$store.commit('clearDuyuruDetay')
    },
    kategoriSave() {
      if (this.kategoriName) {
        this.$store
            .dispatch('kategoriSave', { baslik: this.kategoriName })
            .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
              this.kategoriName = ''
              const mesaj = this.$store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Başarılı',
                        text: mesaj,
                        icon: 'ThumbsUpIcon',
                        variant: 'success',
                      },
                    },
                    { position },
                )
              }
            })
            .catch(err => {
              const mesaj = this.$store.getters.notificationSettings(err)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Kategori Adını Giriniz',
            icon: 'ThumbsDownIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>

<style scoped>
.row {
  justify-content: end !important;
}

.table th,
.table td {
  padding: 0 !important;
  border-top: 1px solid #ebe9f1;
  max-width: 450px !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

.card-body {
  padding: 10px 15px;
}

.myRow {
  justify-content: center !important;
}
</style>
<style>
.duyuruKategoriCard .table th,
.duyuruKategoriCard .table td {
  padding: 3px !important;
}

.ql-container {
  height: 250px;
}

.duyuru-row {
  justify-content: space-between !important;
  display: flex !important;
}
</style>
